html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  position: relative;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  overflow-x: hidden;
}

html {
  font-size: 12px;
}

@media screen and (width >= 320px) {
  html {
    font-size: calc(.454545vw + 10.5455px);
  }
}

@media screen and (width >= 1200px) {
  html {
    font-size: 16px;
  }
}

h1, h2, h3, h4, h5, h6, button {
  font-family: Roboto, sans-serif;
}

p, a, li, small {
  font-family: Open Sans, sans-serif;
}

.marketing, .intro__illustration, .customization__illustration, .notification__illustration, .daysoff__illustration {
  vertical-align: middle;
  width: 50%;
  display: inline-block;
}

@media (width <= 599px) {
  .marketing, .intro__illustration, .customization__illustration, .notification__illustration, .daysoff__illustration {
    width: 100%;
  }
}

.customization, .notification, .daysoff, .cta, .intro, footer {
  padding-top: 75px;
  padding-bottom: 75px;
  font-size: 0;
  position: relative;
}

.js-reveal {
  opacity: 0;
  transition: opacity .3s ease-in, transform .4s ease-in;
  transform: translateY(20px);
}

.js-reveal.shown {
  opacity: 1;
  transform: translateY(0);
}

button, a.button--primary {
  cursor: pointer;
  vertical-align: top;
  border: 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
}

@media screen and (width >= 320px) {
  button, a.button--primary {
    font-size: calc(.454545vw + 10.5455px);
  }
}

@media screen and (width >= 1200px) {
  button, a.button--primary {
    font-size: 16px;
  }
}

.button--primary {
  background-color: #111b47;
  border: 2px solid #111b47;
  padding: 10px 20px;
  display: inline-block;
  color: #fff !important;
}

.button--primary:hover {
  background-color: #070b1e;
}

.button--dropdown {
  background: none;
  border: 2px solid #111b47;
  outline: none;
  width: 13rem;
  height: 2.5rem;
  position: relative;
}

.button--dropdown:after {
  content: "";
  border: 5px solid #0000;
  border-top-color: #111b47;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  right: 20px;
}

.button--dropdown:hover {
  background-color: #e6e6e6;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown ul {
  opacity: 0;
  pointer-events: none;
  border: 2px solid #111b47;
  border-top: 0 solid #0000;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  width: 100%;
  padding-top: 3px;
  transition: opacity .2s ease-in, transform .3s ease-in;
  display: block;
  transform: translateY(-10px);
}

.dropdown ul.opened {
  opacity: .7;
  pointer-events: initial;
  transform: translateY(-3px);
}

.dropdown ul li {
  display: block;
}

.dropdown ul li a {
  color: #37315c;
  width: 100%;
  padding: 10px 0;
  font-size: 10px;
  font-weight: 600;
  text-decoration: none;
  transition: all .1s ease-in;
  display: block;
}

@media screen and (width >= 320px) {
  .dropdown ul li a {
    font-size: calc(.454545vw + 8.54546px);
  }
}

@media screen and (width >= 1200px) {
  .dropdown ul li a {
    font-size: 14px;
  }
}

.dropdown ul li a:hover {
  background: #9a93c6;
}

.header__nav {
  z-index: 3;
  width: 100%;
  transition: top .3s ease-in, background .2s ease-in;
  position: fixed;
  top: 0;
}

.header__nav a {
  color: #000;
  text-decoration: none;
}

.header__nav li {
  white-space: nowrap;
  padding: 15px 5px;
}

@media (width <= 599px) {
  .header__nav {
    display: none;
  }
}

.header__nav {
  padding: 5px 5%;
}

.header__nav__logo img {
  width: 40px;
}

.header__nav ul {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__nav__toggle {
  order: 1;
}

.header__nav__item.header__nav__button {
  order: 2;
}

.header__nav__item {
  text-align: center;
  order: 3;
  width: 100%;
  display: none;
}

.header__nav__item.active {
  display: block;
}

@media (width >= 600px) {
  .header__nav ul {
    justify-content: center;
  }

  .header__nav__logo {
    flex: 1;
  }

  .header__nav__toggle {
    text-align: right;
    flex: 1;
  }

  .header__nav__item.header__nav__button {
    order: 1;
    width: auto;
    display: block;
  }

  .header__nav__toggle {
    order: 2;
  }
}

@media (width >= 900px) {
  .header__nav__item {
    width: auto;
    display: block;
  }

  .header__nav__toggle {
    display: none;
  }

  .header__nav__logo {
    order: 0;
  }

  .header__nav__item {
    order: 1;
  }

  .header__nav__item.header__nav__button {
    order: 2;
  }

  .header__nav li {
    padding: 15px 10px;
  }

  .header__nav li.header__nav__button {
    margin-left: 20px;
    padding-right: 0;
  }
}

.intro {
  background: url("herobg.c2c04d03.svg") 100% / contain no-repeat;
}

.intro__illustration {
  text-align: right;
}

.intro__illustration img {
  width: auto;
  max-height: 100vh;
}

@media (width <= 599px) {
  .intro__illustration img {
    width: 100%;
  }
}

.intro__title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 18px;
}

@media screen and (width >= 320px) {
  .intro__title {
    font-size: calc(3.40909vw + 7.09091px);
  }
}

@media screen and (width >= 1200px) {
  .intro__title {
    font-size: 48px;
  }
}

.intro__title img {
  vertical-align: middle;
  width: 50px;
  transform: translateY(-5px);
}

.marketing__container {
  width: 70%;
  margin: auto;
}

@media (width <= 599px) {
  .marketing__container {
    width: 90%;
  }
}

.marketing__desc {
  margin-bottom: 50px;
  font-size: 12px;
  line-height: 1.5;
}

@media screen and (width >= 320px) {
  .marketing__desc {
    font-size: calc(.454545vw + 10.5455px);
  }
}

@media screen and (width >= 1200px) {
  .marketing__desc {
    font-size: 16px;
  }
}

@media (width <= 599px) {
  .marketing__desc {
    text-align: justify;
  }
}

.intro__buttons {
  text-align: center;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.intro__buttons .dropdown, .intro__buttons .button--primary {
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.intro__buttons .button--primary {
  width: 13rem;
  height: 2.5rem;
}

@media (width >= 900px) {
  .intro__buttons {
    flex-direction: row;
    align-items: flex-start;
  }
}

.marketing__title {
  margin-bottom: 30px;
  font-size: 16px;
}

@media screen and (width >= 320px) {
  .marketing__title {
    font-size: calc(1.81818vw + 10.1818px);
  }
}

@media screen and (width >= 1200px) {
  .marketing__title {
    font-size: 32px;
  }
}

.marketing__features {
  font-size: 0;
}

.marketing__feature {
  vertical-align: top;
  width: 50%;
  display: inline-block;
}

.marketing__feature:first-of-type {
  padding-right: 20px;
}

.marketing__feature:last-of-type {
  padding-left: 20px;
}

.marketing__feature__title {
  margin: 20px 0;
  font-size: 12px;
  font-weight: 500;
}

@media screen and (width >= 320px) {
  .marketing__feature__title {
    font-size: calc(.454545vw + 10.5455px);
  }
}

@media screen and (width >= 1200px) {
  .marketing__feature__title {
    font-size: 16px;
  }
}

.marketing__feature__desc {
  font-size: 10px;
  line-height: 1.5;
}

@media screen and (width >= 320px) {
  .marketing__feature__desc {
    font-size: calc(.454545vw + 8.54546px);
  }
}

@media screen and (width >= 1200px) {
  .marketing__feature__desc {
    font-size: 14px;
  }
}

.customization__illustration {
  margin-top: 150px;
  position: relative;
}

.customization__illustration img:not(:first-child) {
  position: absolute;
}

.customization__illustration img:nth-child(2) {
  top: 0;
  left: 0;
  transform: translate3d(50px, -50px, 0);
}

.customization__illustration img:nth-child(3) {
  top: 0;
  left: 0;
  transform: translate3d(100px, -100px, 0);
}

.notification {
  position: relative;
}

.notification:after {
  opacity: .5;
  content: "";
  z-index: -1;
  background-color: #9a93c6;
  display: block;
  position: absolute;
  inset: 0;
}

.notification__illustration {
  position: relative;
}

.notification__illustration img:last-of-type {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate3d(100px, -100px, 0);
}

@media (width <= 599px) {
  .notification__illustration img {
    width: 100%;
  }
}

.daysoff__image {
  width: 100%;
}

@media (width <= 599px) {
  .daysoff > .marketing {
    margin-top: 30px;
  }
}

.daysoff__illustration {
  transform: translateX(-100px);
}

.daysoff__illustration .daysoff__animation {
  position: absolute;
  top: 0;
  left: 0;
}

.cta {
  position: relative;
}

.cta:after {
  opacity: .3;
  content: "";
  z-index: -1;
  background-color: #9a93c6;
  display: block;
  position: absolute;
  inset: 0;
}

.cta__desc {
  text-align: center;
  max-width: 40%;
  margin: 0 auto 50px;
  font-size: 12px;
  line-height: 1.5;
}

@media screen and (width >= 320px) {
  .cta__desc {
    font-size: calc(.454545vw + 10.5455px);
  }
}

@media screen and (width >= 1200px) {
  .cta__desc {
    font-size: 16px;
  }
}

@media (width <= 599px) {
  .cta__desc {
    text-align: justify;
    max-width: 90%;
  }
}

.cta__free {
  text-align: center;
  max-width: 40%;
  margin: 0 auto;
}

@media (width <= 599px) {
  .cta__free {
    max-width: 70%;
  }
}

.cta__free__desc, .cta__free__money {
  color: #222f65;
}

.cta__free__money {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

@media screen and (width >= 320px) {
  .cta__free__money {
    font-size: calc(3.40909vw + 7.09091px);
  }
}

@media screen and (width >= 1200px) {
  .cta__free__money {
    font-size: 48px;
  }
}

.cta__free__desc {
  font-size: 12px;
}

@media screen and (width >= 320px) {
  .cta__free__desc {
    font-size: calc(.454545vw + 10.5455px);
  }
}

@media screen and (width >= 1200px) {
  .cta__free__desc {
    font-size: 16px;
  }
}

.cta__free__punchline {
  color: #5d6970;
  margin: 50px 0 20px;
  font-size: 10px;
}

@media screen and (width >= 320px) {
  .cta__free__punchline {
    font-size: calc(.454545vw + 8.54546px);
  }
}

@media screen and (width >= 1200px) {
  .cta__free__punchline {
    font-size: 14px;
  }
}

@media (width <= 599px) {
  .button--primary {
    width: 13rem;
    height: 2.5rem;
  }
}

footer {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 0;
  position: relative;
}

footer:after {
  opacity: .5;
  content: "";
  z-index: -1;
  background-color: #9a93c6;
  display: block;
  position: absolute;
  inset: 0;
}

footer small {
  color: #6e7477;
  padding: 0 5% 20px;
  font-size: 14px;
  display: block;
}

.footer__nav {
  padding: 30px 5% 0;
  font-size: 14px;
  display: none;
}

.footer__nav li {
  margin-right: 50px;
  display: inline-block;
}

.footer__nav li a {
  color: #6e7477;
  text-decoration: none;
}

.footer__nav:before {
  content: "";
  opacity: .4;
  background: #6e7477;
  width: 100%;
  height: 1px;
  display: block;
  transform: translateY(-20px);
}

.loader {
  content: "";
  z-index: 999;
  background: #fff;
  transition: opacity .3s ease-in-out;
  display: block;
  position: fixed;
  inset: 0;
}

.loader.closed {
  opacity: 0;
  pointer-events: none;
}
/*# sourceMappingURL=index.e87a8f8d.css.map */
