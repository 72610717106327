@use "breakpoints";

.header__nav{
  position: fixed;
    width: 100%;
    z-index: 3;
    top: 0;
    transition: top 0.3s ease-in, background 0.2s ease-in;
    a{
        color: #000;
        text-decoration: none;
    }

    li {
        padding: 15px 5px;
        white-space: nowrap;
    }

    @include breakpoints.for-phone-only {
        display: none;
    }
}


.header__nav{
    padding: 5px 5%;
}
.header__nav__logo img{
    width: 40px;
}

  /* Mobile menu */
  .header__nav ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .header__nav__toggle {
    order: 1;
  }
  .header__nav__item.header__nav__button {
    order: 2;
  }
  .header__nav__item {
    width: 100%;
    text-align: center;
    order: 3;
    display: none;
  }
  .header__nav__item.active {
    display: block;
  }
  /* Tablet menu */
  @media all and (min-width: 600px) {
    .header__nav ul {
      justify-content: center;
    }
    .header__nav__logo {
      flex: 1;
    }
    .header__nav__toggle {
      flex: 1;
      text-align: right;
    }
    .header__nav__item.header__nav__button {
      width: auto;
      order: 1;
      display: block;
    }
    .header__nav__toggle {
      order: 2;
    }
    }
  /* Desktop menu */
  @media all and (min-width: 900px) {
    .header__nav__item {
      display: block;
      width: auto;
    }
    .header__nav__toggle {
      display: none;
    }
    .header__nav__logo {
      order: 0;
    }
    .header__nav__item {
      order: 1;
    }
    .header__nav__item.header__nav__button {
      order: 2;
    }
    .header__nav li {
      padding: 15px 10px;
    }
    .header__nav li.header__nav__button {
      padding-right: 0;
      margin-left: 20px;
    }
  }
