@use "breakpoints";
@use "mixin-font-size";
@use "vars";

.cta {
    position: relative;
    &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: vars.$lightblue;
        opacity: 0.3;
        display: block;
        content: "";
        z-index: -1;
    }
}

.cta__desc {
    margin-bottom: 50px;
    line-height: 1.5;
    max-width: 40%;
    margin: 0 auto 50px;
    text-align: center;

    @include mixin-font-size.fluid-type(vars.$min_width, vars.$max_width, vars.$min_font, vars.$max_font);

    @include breakpoints.for-phone-only {
        max-width: 90%;
        text-align: justify;
    }
}

.cta__free {
    max-width: 40%;
    margin: 0 auto;
    text-align: center;

    @include breakpoints.for-phone-only {
        max-width: 70%;
    }
}

.cta__free__desc,
.cta__free__money {
    color: vars.$middleblue;
}

.cta__free__money {
    font-weight: 500;
    margin-bottom: 10px;
    @include mixin-font-size.fluid-type(vars.$min_width, vars.$max_width, vars.$min-title, vars.$max-title);
}

.cta__free__desc {
    @include mixin-font-size.fluid-type(vars.$min_width, vars.$max_width, vars.$min_font, vars.$max_font);
}

.cta__free__punchline {
    color: #5d6970;
    margin: 50px 0 20px;
    @include mixin-font-size.fluid-type(vars.$min_width, vars.$max_width, vars.$min_sfont, vars.$max_sfont);
}

.button--primary {
    @include breakpoints.for-phone-only {
        height: 2.5rem;
        width: 13rem;
    }
}
