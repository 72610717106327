@use "breakpoints";
@use "vars";

.marketing, .intro__illustration, .customization__illustration, .notification__illustration, .daysoff__illustration{
    width: 50%;
    display: inline-block;
    vertical-align: middle;

    @include breakpoints.for-phone-only {
        width: 100%;
    }
}



.customization, .notification, .daysoff, .cta, .intro, footer{
    font-size: 0;
    position: relative;
    padding-top: vars.$padding-section;
    padding-bottom: vars.$padding-section;
}
