@use "vars";

footer{
    padding-top: 20px;
    padding-bottom: 0;
    text-align: center;
    position: relative;
    &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: vars.$lightblue;
        opacity: 0.5;
        display: block;
        content: '';
        z-index: -1;
    }

    small{
        font-size: 14px;
        color: #6e7477;
        display: block;
        padding: 0 5% 20px; 
    }
}

.footer__nav{
    display: none;
    font-size: 14px;
    padding: 30px 5% 0;
    li{
        margin-right: 50px;
        display: inline-block;
        a{
            color: #6e7477;
            text-decoration: none;
        }
    }

    &:before{
        background: #6e7477;
        height: 1px;
        width: 100%;
        content: '';
        transform: translateY(-20px);
        display: block;
        opacity: 0.4;
    }
}


