@use "breakpoints";
@use "mixin-font-size";
@use "vars";

.marketing__container {
    width: 70%;
    margin: auto;

    @include breakpoints.for-phone-only {
        width: 90%;
    }
}

.marketing__desc {
    margin-bottom: 50px;
    line-height: 1.5;

    @include mixin-font-size.fluid-type(vars.$min_width, vars.$max_width, vars.$min_font, vars.$max_font);

    @include breakpoints.for-phone-only {
        text-align: justify;
    }
}

.intro__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    margin: 0 auto;

    .dropdown,
    .button--primary {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .button--primary {
        width: 13rem;
        height: 2.5rem;
    }

    @include breakpoints.for-tablet-landscape-up {
        flex-direction: row;
        align-items: flex-start;
    }
}

.marketing__title {
    margin-bottom: 30px;
    @include mixin-font-size.fluid-type(vars.$min_width, vars.$max_width, vars.$min_subtitle, vars.$max_subtitle);
}

.marketing__features {
    font-size: 0;
}

.marketing__feature {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    &:first-of-type {
        padding-right: 20px;
    }
    &:last-of-type {
        padding-left: 20px;
    }
}

.marketing__feature__title {
    font-weight: 500;
    margin: 20px 0;
    @include mixin-font-size.fluid-type(vars.$min_width, vars.$max_width, vars.$min_font, vars.$max_font);
}

.marketing__feature__desc {
    line-height: 1.5;
    @include mixin-font-size.fluid-type(vars.$min_width, vars.$max_width, vars.$min_sfont, vars.$max_sfont);
}
