@use "sass:color";
@use "mixin-font-size";
@use "vars";

button,
a.button--primary {
    border-radius: 4px;
    border: 0px;
    font-weight: 500;
    cursor: pointer;
    vertical-align: top;
    text-decoration: none;
    @include mixin-font-size.fluid-type(vars.$min_width, vars.$max_width, vars.$min_font, vars.$max_font);
}

.button--primary {
    background-color: vars.$blue;
    color: #fff !important;
    border: 2px solid vars.$blue;
    display: inline-block;
    padding: 10px 20px;
    &:hover {
        background-color: color.adjust(#111B47, $lightness: -10%);
    }

}

.button--dropdown {
    background: transparent;
    border: 2px solid vars.$blue;
    position: relative;
    outline: none;
    width: 13rem;
    height: 2.5rem;
    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: vars.$blue transparent transparent transparent;
        right: 20px;
        top: calc(50% - 2px);
    }
    &:hover {
        background-color: color.adjust(#fff, $lightness: -10%);
    }
}

.dropdown {
    display: inline-block;
    position: relative;

    ul {
        opacity: 0;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        border: 2px solid vars.$blue;
        border-top: 0px solid transparent;

        display: block;
        width: 100%;
        padding-top: 3px;

        transform: translateY(-10px);
        transition: opacity 0.2s ease-in, transform 0.3s ease-in;

        pointer-events: none;

        &.opened {
            opacity: 0.7;
            transform: translateY(-3px);
            pointer-events: initial;
        }
        li {
            display: block;

            a {
                font-weight: 600;
                text-decoration: none;
                width: 100%;
                padding: 10px 0;
                display: block;
                color: color.adjust(vars.$lightblue, $lightness: -40%);
                transition: all 0.1s ease-in;
                @include mixin-font-size.fluid-type(
                    vars.$min_width,
                    vars.$max_width,
                    vars.$min_sfont,
                    vars.$max_sfont
                );
                &:hover {
                    background: vars.$lightblue;
                }
            }
        }
    }
}
