.loader{
    display: block;
    content: '';
    position: fixed;
    background: #fff;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 999;
    transition: opacity 0.3s ease-in-out;
    &.closed{
        opacity: 0;
        pointer-events: none;
    }
}