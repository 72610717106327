@use "breakpoints";
@use "mixin-font-size";
@use "vars";

.intro{
    background: url('./images/herobg.svg') no-repeat center right;
    background-size: contain;
}


.intro__illustration{
    text-align: right;
    img{
        max-height: 100vh;
        width: auto;
    }

    @include breakpoints.for-phone-only {
        img{
            width: 100%;
        }
    }
}


.intro__title{
    margin-bottom: 30px;
    text-align: center;
    
    @include mixin-font-size.fluid-type(vars.$min_width, vars.$max_width, vars.$min_title, vars.$max_title);
    img{
        width: 50px;
        vertical-align: middle;
        transform: translateY(-5px);
    }
}
