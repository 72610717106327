@use "breakpoints";
@use "vars";

.notification{
    position: relative;
    &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: vars.$lightblue;
        opacity: 0.5;
        display: block;
        content: '';
        z-index: -1;
    }
}

.notification__illustration{
    position: relative;

    img{
        &:last-of-type{
            position: absolute;
            top: 0;
            right: 0;
            transform: translate3d(100px, -100px, 0);
        }

        @include breakpoints.for-phone-only {
            width: 100%;
        }
    }
}
