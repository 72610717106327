@use "breakpoints";

.daysoff__image {
    width: 100%
}

.daysoff > .marketing {

    @include breakpoints.for-phone-only {
        margin-top: 30px;
    }

}

.daysoff__illustration{
    transform: translateX(-100px);
    .daysoff__animation{
        position: absolute;
        left:0;
        top:0;
    }
}