.customization__illustration{
    margin-top: 150px;
    position: relative;
    img:not(:first-child){
        position: absolute;
    }

    img:nth-child(2){
        left: 0;
        top: 0;
        transform: translate3d(50px, -50px, 0)
    }
    img:nth-child(3){
        left: 0;
        top: 0;
        transform: translate3d(100px, -100px, 0)
    }
}


