// REVEAL
.js-reveal{
	opacity: 0;
	transform: translateY(20px);
	transition: opacity .3s ease-in, transform .4s ease-in;

	&.shown{
		opacity: 1;
		transform: translateY(0);
	}
}


